import React from 'react'
import { blueColor } from "./../styles";

const OrientaciaVSlovniku = () => {
	return (
		<div >
			<h2>
				Ako sa v slovníku orientovať?
			</h2>
			<div style={{ lineHeight: "140%" }}>
				<p>
					Slovník umožňuje vyhľadávať heslá v slovenčine v časti <b>Slovensko-rómsky</b> a heslá v rómčine v časti <b>Rómsko-slovenský</b>.
				</p>
				<p>
					<span style={{ color: blueColor }}><b>Heslový výraz</b></span> je graficky odlíšený tučným písmom a modrou farbou. <span style={{ color: blueColor }}>Príklady použitia</span> daného výrazu v zaužívaných slovných spojeniach a vo vetách sú tiež odlíšené modrou farbou. Heslové výrazy, ktoré majú rovnakú podobu, ale odlišný význam alebo funkciu sú uvádzané samostatne.
				</p>
				<p>
					K heslovým výrazom sú priradené vhodné <b>prekladové ekvivalenty</b>, ktoré sú odlíšené tučným písmom. Ekvivalencia môže byť jednoznačná alebo čiastočná, niekedy je možný len preklad opisom alebo pomocou idiomatických výrazov.
				</p>
				<p>
					V slovníku zvyčajne nájdete informáciu <i>o slovnodruhovej príslušnosti slov, v prípade mien je uvádzaný aj rod a číslo</i> (ak je heslový výraz uvedený v množnom čísle).
				</p>
				<p>
					V niektorých heslových statiach sú uvedené aj <u>rôzne tvary</u> rómskeho výrazu a jeho <u>pôvod</u>. Pri podstatných menách sa uvádzajú tvary množné čísla, nepriameho kmeňa a skloňovanie v jednotnom čísle. Pri prídavných menách sa uvádzajú tvary ženského rodu, množného čísla oboch rodov a tvary 2. a 3. stupňa (ak sa meno stupňuje). Pri slovesách sa uvádzajú tvary slovesa v 3. osobe j. č. vo všetkých časoch a spôsoboch (okrem rozkazovacieho spôsobu) a tiež tvar slovesného príčastia. Pri príslovkách, ktoré sa stupňujú sa uvádzajú tvary 2. a 3. stupňa.
				</p>
				<p>
					Pri niektorých rómskych výrazoch je uvedená poznámka o oblasti ich použitia – <i>regionálnym výrazom</i> sa myslí výraz rozšírený v niektorom z regiónov Slovenska, <i>medzinárodným výrazom</i> sa myslí výraz rozšírený vo viacerých dialektoch rómskeho jazyka, ktorými hovoria Rómovia v celej Európe.
				</p>
			</div>
		</div >
	)
}

export default OrientaciaVSlovniku
