/**
 * @type {UserConfig}
 */
const pluginConfig = {
  current_lang: 'sk',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  gui_options: {
    consent_modal: {
      layout: 'bar',               // box/cloud/bar
      position: 'bottom center',     // bottom/middle/top + left/right/center
      transition: 'slide',           // zoom/slide
      swap_buttons: false            // enable to invert buttons
    },
  },

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
    const analyticsEnabled = window.CC.allowedCategory('analytics');
  },

  onAccept: function (cookie) {
    // ...
  },

  onChange: function (cookie, changed_preferences) {
    // ...
  },

  languages: {
    sk: {
      consent_modal: {
        title: 'Povoliť používanie súborov cookies v tomto prehliadači?',
        description:
          "Podľa zákona potrebujeme Váš súhlas na to, aby sme mohli zbierať informácie o návštevnosti našej stránky. Štatistické informácie, ktoré naša webová stránka zbiera sú anonymné a slúžia výlučne na to aby sme vedeli koľko ľudí a z akých krajín stránku navštevuje. Vaším súhlasom nám vyjadríte veľkú podporu. Čím vyššia návštevnosť, tým väčšia pravdepodobnosť, že sa budeme môcť venovať rozvoju stránky aj v budúcnosti.",
        primary_btn: {
          text: 'Súhlasím',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Nesúhlasím',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        cookie_table_headers: [
          { col1: 'Name' },
        ],
        blocks: [
          {
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: '^_ga',
                is_regex: true
              },
            ]
          },
        ],
      },
    },
  },
};

export default pluginConfig;
