import React from 'react'
import './Header.css'
import Link from '@mui/material/Link'

const Header = () => {
	return (
		<Link href="/" color="inherit" underline="none">
			<div className='header'>
				<h1 className='title'>Slovensko-rómsky slovník</h1>
			</div>
		</Link>
	)
}

export default Header 