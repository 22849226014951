import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import ONas from "./Pages/o-nas";
import OrientaciaVSlovniku from "./Pages/orientacia-v-slovniku";
import Home from "./Pages/home";
import Header from "./components/Header/Header";
import Zdroje from "./Pages/zdroje";

//This order is important
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './App.css'

import CookieConsentComponent from "./cookies/CookieConsent";

const App = () => {	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<CookieConsentComponent />
			<Header />
			<Container maxWidth='md'>
				
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/:language" element={<Home />} />
						<Route path="/:language/:searchTermFromUrl" element={<Home />} />

						<Route path="/o-nas" element={<ONas />} />
						<Route path="/orientacia-v-slovniku" element={<OrientaciaVSlovniku />} />
						<Route path="/zdroje" element={<Zdroje />} />
					</Routes>
				
			</Container>
			<Footer />
		</Box>
	)
}

export default App
