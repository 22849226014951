import React from 'react';
import './Definitions.css';
import { blueColor } from '../../styles';
import { stylizeText } from '../../utils/text';

const DefinitionsSK = ({ signs }: { signs: any }) => {
	return (
		<>
			{signs?.map((sign: any, idx: number) => {
				const data = sign.data;
				return (
					<div key={idx}>
						<div>
							<span style={{ color: blueColor }}>
								<b>{`${data.sign} ${data.valencia ? `(${data.valencia})` : ''
									}`}</b>
							</span>{' '}
							{data.partOfSpeech && (
								<span style={{ fontSize: '12px' }}>
									<i>({data.partOfSpeech.split(',').join(', ')})</i>
								</span>
							)}{' '}
							{data.vyznamLemy && <span dangerouslySetInnerHTML={{ __html: stylizeText(`(=${data.vyznamLemy})`) }} />}
						</div>
						<div>
							<ul>
								{data.senses?.map((sense: any, idx: number) => (
									<li key={idx}>
										<div>
											<b>
												{sense.definition}{' '}
												{sense.valencia ? `${sense.valencia}` : ''}
											</b>{' '}
											{sense.partOfSpeech ? (
												<span style={{ fontSize: '12px' }}>
													<i>({sense.partOfSpeech.split(',').join(', ')})</i>
												</span>
											) : null}
										</div>
										{sense.poznamka && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													poznámka:
												</u>
												{sense.poznamka}
											</div>
										)}
										{sense.povod && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													pôvod:
												</u>
												<span dangerouslySetInnerHTML={{ __html: stylizeText(sense.povod.povod) }} />
											</div>
										)}
										{sense.povod?.zdroj && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													zdroj:
												</u>
												<span style={{ fontSize: '12px', paddingRight: '5px' }}>{sense.povod.zdroj}</span>
											</div>
										)}
										{sense.mnozne && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													množné číslo:
												</u>
												{sense.mnozne}
											</div>
										)}
										{sense.muzskymnozne && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													mužský rod, množné číslo:
												</u>
												{sense.muzskymnozne}
											</div>
										)}
										{sense.zenskyjednotne && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													ženský rod, jednotné číslo:
												</u>
												{sense.zenskyjednotne}
											</div>
										)}
										{sense.zenskymnozne && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													ženský rod, množné číslo:
												</u>
												{sense.zenskymnozne}
											</div>
										)}
										{sense.zdrobnenina && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													zdrobnenina:
												</u>
												{sense.zdrobnenina}
											</div>
										)}
										{sense.nepriamykmen && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													nepriamy kmeň:
												</u>
												{sense.nepriamykmen}
											</div>
										)}
										{sense.neprimaypad && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													nepriamy pád:
												</u>
												{sense.nepriamypad}
											</div>
										)}
										{sense.nominativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													nominatív:
												</u>
												{sense.nominativ}
											</div>
										)}
										{sense.akuzativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													akuzatív:
												</u>
												{sense.akuzativ}
											</div>
										)}
										{sense.vokativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													vokatív:
												</u>
												{sense.vokativ}
											</div>
										)}
										{sense.dativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													datív:
												</u>
												{sense.dativ}
											</div>
										)}
										{sense.lokativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													lokatív:
												</u>
												{sense.lokativ}
											</div>
										)}
										{sense.instrumental && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													inštrumentál:
												</u>
												{sense.instrumental}
											</div>
										)}
										{sense.ablativ && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													ablatív:
												</u>
												{sense.ablativ}
											</div>
										)}
										{sense.genitiv && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													genitív:
												</u>
												{sense.genitiv}
											</div>
										)}
										{sense.druhystupen && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													2. stupeň:
												</u>
												{sense.druhystupen}
											</div>
										)}
										{sense.tretistupen && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													3. stupeň:
												</u>
												{sense.tretistupen}
											</div>
										)}
										{sense.nepriamypad && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													nepriamy pád:
												</u>
												{sense.nepriamypad}
											</div>
										)}
										{sense.pricastie && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													príčastie:
												</u>
												{sense.pricastie}
											</div>
										)}
										{sense.mincasdok && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													minulý čas dokonavý:
												</u>
												{sense.mincasdok}
											</div>
										)}
										{sense.mincasnedok && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													minulý čas nedokonavý:
												</u>
												{sense.mincasnedok}
											</div>
										)}
										{sense.budcas && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													budúci čas:
												</u>
												{sense.budcas}
											</div>
										)}
										{sense.podmiendok && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													podmieňovací spôsob prítomný:
												</u>
												{sense.podmiendok}
											</div>
										)}
										{sense.podmiennedok && (
											<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
												<u style={{ fontSize: '12px', paddingRight: '5px' }}>
													podmieňovací spôsob minulý:
												</u>
												{sense.podmiennedok}
											</div>
										)}
										<ul style={{ paddingLeft: '25px', paddingTop: '10px' }}>
											{sense.examples?.map((example: any, idx: number) => (
												<li key={idx}>
													<div>
														<div style={{ color: blueColor }}>
															{example.example}
														</div>
														{example.poznamka && (
															<div style={{ paddingBottom: '5px' }}>
																<u style={{ fontSize: '12px', paddingRight: '5px' }}>
																	poznámka:
																</u>
																{example.poznamka}
															</div>
														)}
														<div>{example.translation}</div>
													</div>
												</li>
											))}
										</ul>
										<br />
									</li>
								))}
							</ul>
						</div>
						{idx + 1 < signs.length ? <hr /> : null}
					</div >
				);
			})}
		</>

	);
};

export default DefinitionsSK;
