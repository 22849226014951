import React from 'react'
import Link from "@mui/material/Link";

const Zdroje = () => {
	return (
		<div style={{ fontSize: "14px" }}>
			<h2>
				EXCERPČNÉ ZDROJE A ICH ZNAČENIE V SLOVNÍKU
			</h2>
			<p><b>ACR</b> = Atlas of Central Romani. Dostupné na: <Link href="http://atlasofcentralromani.ff.cuni.cz/#/" color="inherit" >http://atlasofcentralromani.ff.cuni.cz/#/</Link></p>
			<p><b>Aichele 1957</b> = Aichele, W. (1957). Die ursprünglich aspirierten Medien der Zigeunersprache. In: Zeitschrift für Phonetik und allgemeine Sprachwissenschaft. Band 10. Heft 2. pp. 101-107.</p>
			<p><b>Beekes EDG 2010</b> = Beekes, R. S. P. (2010). Etymological Dictionary of Greek. Leiden – Boston: Brill.</p>
			<p><b>Beníšek 2006</b> = Beníšek, M., (2006). Ke kořenům slova ROM. In: Romano džaniben, jevend 2006: 9–27.</p>
			<p><b>Beníšek 2008</b> = Beníšek, M. (2008). Poznámky k protoromštině/Notes on Proto-Romani. Diplomová práce (Univerzita Karlova, Praha).</p>
			<p><b>Beníšek 2013a</b> = Beníšek, M. 2013. Central Romani lidža-/ledž-: a vestige of an Indo-Aryan compound verb and its cross-dialectal variability. Acta Orientalia Academiae Scientiarum Hungaricae 66. 4: 471–486.</p>
			<p><b>Beníšek 2013b</b> = Beníšek, M. 2013. Predicative possession in Western Transcarpathian Romani. 7th International Conference on Language Variation in Europe (ICLaVE). Trondheim. 26–28 June 2013</p>
			<p><b>Beníšek 2020</b> = Beníšek, M. (2020). The historical origins of Romani. In: Matras, Y., Tenser, A., The Palgrave handbook of Romani language and linguistics. Palgrave Macmillan Ltd.</p>
			<p><b>Boretzky 1995</b> = Boretzky, N. (1995). Armenisches im Zigeunerischen (Romani und Lomavren). In: Indogermanische Forschungen, pp. 137-155.</p>
			<p><b>Boretzky 2012</b> = Boretzky, N. (2012). Studien zum Wortschatz des Romani: I. Erbwörter des Romani; II. Iranismen und Armenismen des Romani. Veliko Tărnovo: Faber.</p>
			<p><b>Boretzky, Igla 2004</b> = Boretzky, N., Igla, B. (2004). Kommentierter Dialektatlas des Romani. Wiesbaden: Harrassowitz. </p>
			<p><b>Courthiade 2007</b> = Courthiade, M. (2007). Sagesse et humour du peuple rrom: Sar o rromano ilo, nanaj p-i sasti phuv. Paríž: L’Harmattan.</p>
			<p><b>Courthiade 2009</b> = Courthiade, M. (2009). Morri angluni rromane ćhibaqi evroputni lavustik. Budapešť: Romano kher.</p>
			<p><b>Čalo voďi</b> = Kramářová, J. - Sadílková, H. (Ed.). (2007). Čalo voďi/Sytá duše. Antologie prozaických textů romských autorů z ČR. Brno: Muzeum romské kultury.</p>
			<p><b>Demeterová 1994</b> = Demeterová, H. (1994). Rom ke Romeste drom arakhel/Rom k Romovi cestu najde. Praha: Sdružení romských autorů - ROMAŇI ČHIB.</p>
			<p><b>Elšík 2009</b> = Elšík, V. (2009). Loanwords in Selice Romani, an Indo-Aryan language of Slovakia. In: Loanwords in the world's languages: A comparative handbook. Berlin: Mouton De Gruyter. pp. 1-76.</p>
			<p><b>Fabiánová 1992</b> = Fabiánová, T. (1992). Sar me phiravas andre škola/Jak jsem chodila do školy. Nakladatelství a vydavatelství ÚDO České Budějovice ve spolupráci se Společenstvím Romů na Moravě.</p>
			<p><b>Fabiánová, Hübschmannová 1991</b> = Fabiánová, T., Hübschmannová, M. (1991). Čavargoš/Tulák. Praha: Apeiron.</p>
			<p><b>Ferková 1992</b> = Ferková, I. (1992). Mosarďa peske o dživipen. Praha: Romaňi čhib.</p>
			<p><b>Ferková 1996</b> = Ferková, I. (1996). Čorde čhave/Ukradené děti. Společenství Romů na Moravě.</p>
			<p><b>Ferková 2018</b> = FERKOVÁ, I. (2018). Ještě jedno, Lído! Kaštánkovy příběhy z herny./De mek jekh, Ľido! Kaštankuskre vakeribena andal e herňa. Praha: KHER.</p>
			<p><b>Friedman 1988</b> = Friedman, V., A. (1988). A Caucasian loanword in Romani. In: DeSilva, Cara, Grumet, Joanne & Nemeth, David J. (Eds.) Papers from the eighth and ninth annual meetings, Gypsy Lore Society, North American Chapter. New York: Gypsy Lore Society, North American Chapter. 18–20</p>
			<p><b>Giňa 2013</b> = Giňa, A. (2013). PAŤIV Ještě víme, co je úcta, Praha: Triáda.</p>
			<p><b>Goďaver lava</b> = Hübschmannová, M. (1991). Goďaver lava phure Romendar/Moudrá slova starých Romů. Praha: Aperion.</p>
			<p><b>Haluška 2003</b> = Haluška, V. (2003). Pal le Devleskero Sidorkus/O božím Sidorkovi. Praha: Signeta.</p>
			<p><b>Hancock 1988</b> = Hancock, I. (1988). The development of Romani Linguistics. In: Jazayrey, M., A., Winter, W. (1988). Language and Cultures. Studies in Honor of Edgar C. Polomé. Berlin-New York-Amsterdam: Mouton de Gruyter.</p>
			<p><b>Horváthová A. 2003</b> = Horváthová, A. (2003). Pal e Bari Rama the aver paramisa/O Velké Rámě a jiné příběhy. Praha: Signeta.</p>
			<p><b>Hübschmannová 1993</b> = Hübschmannová, M. (1993). Šaj pes dovakeras/Můžeme se domluvit. 1. vyd., Olomouc: Pedag. fakulta UP.</p>
			<p><b>IEW </b> = Pokorny, J. (1959–1969). Indogermanisches etymologisches Wörterbuch. Bern – München: Francke.</p>
			<p><b>Kent 1953</b> = Kent, R., G. (1953). Old Persian. Grammar. Texts. Lexicon. Second edition. New Haven: American Oriental Society. </p>
			<p><b>Kováčová V. 2021</b> = Kováčová, V. (2021). Sunoro – Sen. OZ Cesta von.</p>
			<p><b>Králik SESS 2015</b> = Králik, Ľ. (2015). Stručný etymologický slovník slovenčiny. Bratislava: VEDA.</p>
			<p><b>Králik SESS 2019</b> = Králik, Ľ. (2019). Stručný etymologický slovník slovenčiny. Bratislava: VEDA.</p>
			<p><b>Lacková 1992</b> = Lacková, E. (1992). Rómske rozprávky (Romane paramisa). Košice: Východoslovenské vydavateľstvo Košice.</p>
			<p><b>Lacková 1997</b> = Lacková, E. (1997). Narodila jsem se pod šťastnou hvězdou. Praha: Triáda.</p>
			<p><b>Lacková 1999</b> = Lacková, E. (1999). Romane paramisa/Romské pohádky. Praha: Radix.</p>
			<p><b>LIV<sup>2</sup></b> = Rix, H., Kümmel, M., Zehnder, TH., Lipp, R., Schirmer, B. (2001). Lexikon der indogermanischen Verben. Die Wurzeln und ihre Primärstammbildungen. Wiesbaden: Reichert.</p>
			<p><b>Lundgrenová, Taikonová, Erikssonová</b> = Lundgrenová, G., Taikonová, S., Erikssonová, A. (2022). Žofi Z-4515. Praha: KHER.</p>
			<p><b>Mānuš 1994</b> = Mānuš, L. (1994). “Le etimologie di venti vocaboli zingari.” Lacio Drom 30: 26–34. </p>
			<p><b>Mānuš 1997</b> = Mānušs, L., Neilands, J. & Rudevičs, K. (1997). Čigānu–latviešu–angļu etimoloģiskā vārdnīca un latviešu–čigānu vārdnīca. [Gypsy–Latvian–English etymological dictionary and Latvian–Gypsy dictionary.] Rīgā: Zvaigzne ABC.</p>
			<p><b>Masica 1991</b> = Masica, C., P.  (1991). The Indo-Aryan languages. Cambridge: Cambridge University Press. </p>
			<p><b>Matras 2002</b> = Matras, Y. (2002). Romani: A linguistic introduction. Cambridge: Cambridge University Press.</p>
			<p><b>Mayrhofer EWA I.</b> = Mayrhofer, M. (1992). Etymologisches Wörterbuch des Altindoarischen. I. Band. Heidelberg: Carl Winter.</p>
			<p><b>Mayrhofer EWA II.</b> = Mayrhofer, M. (1996). Etymologisches Wörterbuch des Altindoarischen. II. Band. Heidelberg: Carl Winter</p>
			<p><b>Mayrhofer EWA III.</b> = Mayrhofer, M. (2001). Etymologisches Wörterbuch des Altindoarischen. III. Band. Heidelberg: Carl Winter.</p>
			<p><b>Moji milí</b> = Berkyová, R. - Houdek, L. - Patočková, R. (Ed.). (2014). Moji milí - sbírka romské narativní prózy. Praha: KHER.</p>
			<p><b>Nástin mluvnice 1998</b> = Šebková, H., Žlnayová, E. (1998). Nástin mluvnice slovenské romštiny (pro pedagogické účely), Pedagogická fakulta UJEP v Ústí n. L..</p>
			<p><b>Nyberg 1974</b> = Nyberg, H., S. (1974). A Manual of Pahlavi II. Wiesbaden: Otto Harrassowitz.</p>
			<p><b>O mulo!</b> = Ryvolová, K. (Ed.). (2019). O mulo! Povídky o duchách zemřelých. Sbírka romských autorů. Praha: KHER.</p>
			<p><b>Oláh 2002</b> = Oláh, V. (2002). Khamori luluďi/Slunečnice - Romská poezie. Praha: Radix.</p>
			<p><b>Oláh 2003 </b> = Oláh, V. (2003). Le khameskere čhave/Děti slunce. Praha: Matice romská.</p>
			<p><b>Oláh 2005</b> = Oláh, V. (2005). Khamutno kamibe/Žár lásky. Praha: Matice romská, o.s. sdružení Dženo.</p>
			<p><b>Oláh 2006</b> = Oláh, V. (2006). Amaro drom pal o Udut/Naše cesta za Světlem. Praha: Matice romská.</p>
			<p><b>Oslon-Kožanov ESCJ</b> = Oslon, M., Kožanov, K. (2023). Этимологический словарь цыганского языка (общецыганская лексика)/La řomańa ćhiba-ka etimologija-ko alavari (o sa-řomano leksiko). Dostupné na: <Link href="rromanes.org/pub/ЭСЦЯ.pdf" color="inherit" >rromanes.org/pub/ЭСЦЯ.pdf</Link></p>
			<p><b>Otcův duch</b> = Hlaváčková, I., Houdek, L., Patočková, R. (Ed.). (2012). Otcův duch a jiné pohádky romských autorů. Praha: KHER.</p>
			<p><b>Pay źuvindó</b> = Demetri, M., Dimiter-Taikon, A. (2002). Pay źuvindó, Romané sityarimáta hay divánóri. Štokholm: Skolverket.</p>
			<p><b>PHRLL</b> = Matras, Y., Tenser, A. (2020). The Palgrave handbook of Romani language and linguistics. Palgrave Macmillan Ltd.</p>
			<p><b>PRP 2006</b> = Hübschmannová, M. a kol. (2006). Pravidlá rómskeho pravopisu. Bratislava: Štátny pedagogický ústav.</p>
			<p><b>RČČRKS</b> = Hübschmannová, M., Šebková, H., Žigová, A. (2001). Romsko-český a česko-romský kapesní slovník. Praha: Fortuna.</p>
			<p><b>RČH 2002a</b> = Šebková, H. - Žlnayová, E. (2002). Romaňi čhib. Učebnica rómčiny (severocentrálny dialekt). Košice: BOHEMIA KONTAKT.</p>
			<p><b>RČH 2002b</b> = Šebková, H. - Žlnayová, E. (2002). Romaňi čhib. Kľúč k učebnici rómčiny (severocentrálny dialekt). Košice: BOHEMIA KONTAKT.</p>
			<p><b>RČH 2007</b> = Gašparová, E., Koptová, A., Lukáčová, I., (2007). Romaňi čhib – učebnica rómskeho jazyka, Štátny pedagogický ústav, Bratislava 2007.</p>
			<p><b>RDV</b> = Koptová, A. (1995). Rómčina do vrecka. Košice: Pezolt. </p>
			<p><b>RDŽ</b> = Romano Džaniben - časopis romistických studií. Praha: Společnost přátel časopisu Romano džaniben. Dostupné na: <Link href="https://www.dzaniben.cz/" color="inherit" >https://www.dzaniben.cz/</Link></p>
			<p><b>Reiznerová 1994</b> = Reiznerová, M. (1994). Kaľi. Praha: Sdružení romských autorů - ROMAŇI ČHIB.</p>
			<p><b>Rejzek ČES 2015</b> = Rejzek, J. (2015). Český etymologický slovník. Třetí vydání (druhé přepracované a rozšířené vydání). Praha: Leda.</p>
			<p><b>Romano suno</b> = Romano suno – Romský sen. Sborníky vítězných prací. Dostupné na: <Link href="http://www.novaskolaops.cz/sborniky-viteznych-praci" color="inherit" >http://www.novaskolaops.cz/sborniky-viteznych-praci</Link></p>
			<p><b>ROMLEX</b> = ROMLEX Lexical Database (Lexikálna databáza rómskych dialektov). Dostupné na: <Link href="http://romani.uni-graz.at/romlex/lex.xml" color="inherit" >http://romani.uni-graz.at/romlex/lex.xml</Link></p>
			<p><b>Romské dějiny</b> = Hübschmannová, M. (1998-2002). Romaňi historia – Romské dějiny. In: Amaro gendalos. Praha: Sdružení Dženo.</p>
			<p><b>Romské hádanky</b> = Hübschmannová, M. (Ed.) (2003). Hin man ajsi čhaj, so... Romské hádanky. Praha: Fortuna.</p>
			<p><b>RSFS</b> = Horňáková, M. (2020). Rómsko-slovenský frazeologický slovník, Košice: Nadácia Dobrá rómska víla Kesaj. </p>
			<p><b>Samet blues</b> = Jandáková, L. (Ed.). (2021). Samet blues. Praha: KHER.</p>
			<p><b>Sampson 1926</b> = Sampson, J. (1926). The dialect of the Gypsies of Wales, being the older form of British Romani preserved in the speech of the clan of Abram Wood. Repr. 1968. Oxford: Clarendon Press.</p>
			<p><b>Soravia 1988</b> = Soravia, G. (1988). Di alcune etimologie zingariche. In: Archivio glottologico italiano 73, pp. 3–11.</p>
			<p><b>SRN</b> = Slovník romských neologismů (Seminář romistiky Katedry středoevropských studií FF UK, Praha). Dostupné na: <Link href="https://romstina.ff.cuni.cz/srn/index_srn.php" color="inherit" >https://romstina.ff.cuni.cz/srn/index_srn.php</Link></p>
			<p><b>SRRSS 1996</b> = Berky, J., Prokop, J., Stojka, M. (1996) Slovensko-rómsky rómsko-slovenský slovník. Bratislava: Štúdio dd.</p>
			<p><b>SRRSS 2011</b> = Koptová, A., Koptová, M. (2011). Slovensko-rómsky rómsko-slovenský slovník. Košice: Nadácia Dobrá rómska víla Kesaj.</p>
			<p><b>SRTS</b> = Cina, S. a kol. (2012). Slovensko-rómsky terminologický slovník. Bratislava: Úrad splnomocnenca vlády slovenskej republiky pre národnostné menšiny. Dostupné na: <Link href="https://www.narodnostnemensiny.vlada.gov.sk/odborne-terminologicke-slovniky-v-jazykoch-narodnostnych-mensin/" color="inherit" >https://www.narodnostnemensiny.vlada.gov.sk/odborne-terminologicke-slovniky-v-jazykoch-narodnostnych-mensin/</Link></p>
			{/* <p><b>Ščuka – Hitmejkri</b> = Ščuka, E. Hitmejkri – Bare hirešne lavutara. Praha: Mezinárodní konzervatoř Praha, Muzeum romského národního obrození.</p> */}
			{/* <p><b>Ščuka – O majstri</b> = Ščuka, E. O majstri – ašunde bare manuša andro klasicko bašaviben the andro jazzos. Praha: Mezinárodní konzervatoř Praha, Muzeum romského národního obrození.</p> */}
			{/* <p><b>Ščuka – Uľile</b> = Ščuka, E. (2020). Uľile le vonoha andro vasta – Nekhašundeder romane lavutara andre Československo. Praha: Muzeum romského národního obrození.</p> */}
			{/* <p><b>Ščuka 2020a</b> = Ščuka, E. (2020) Devla miro, sem jov hino Rom! Praha: Knihovna Václava Havla.</p> */}
			{/* <p><b>Ščuka 2020b</b> = Ščuka, E. (2020) Me som Rom romano. Praha: Knihovna Václava Havla.</p> */}
			{/* <p><b>Ščuka 2020c</b> = Ščuka, E. (2020) Jekh čiriklo či kerel ňilaj. Praha: Knihovna Václava Havla.</p> */}
			<p><b>Šebková 2023</b> = Šebková, H. (2003). Bacht the balvaj pheňa hin/Štěstí a vítr jedno jsou. Příchovice: BUK.</p>
			<p><b>Tálos 1999</b> = Tálos, E. (1999). Etymologica Zingarica. In: Acta Linguistica Hungarica. Vol. 46 (3-4). pp. 215-268.</p>
			<p><b>Tsabolov 2010</b> = Tsabolov (2010). Etimologičeskij slovar kurdskogo jazyka. TOM II N-Ž. Moskva: Firma.</p>
			<p><b>Turner 1924</b> = Turner, R., L. (1924). traš- ‘to frighten’. Journal of the Gypsy Lore Society, Third series 3: 38–43.</p>
			<p><b>Turner CDIAL 1966</b> = Turner, R. L. (1966). A comparative dictionary of the Indo-Aryan languages. London: Oxford University Press.</p>
			<p><b>van den Heuvel 2020</b> = van den Heuvel, W. Romani Bible Translation and the Use of Romani in Religious Contexts. In: Matras, Y., Tenser, A., The Palgrave handbook of Romani language and linguistics. Palgrave Macmillan Ltd.</p>
			<p><b>Vekerdi 2002</b> = Vekerdi, J. with the assistance of Zsuzsa Várnai. (2002). A comparative dictionary of Gypsy dialects in Hungary. Gypsy-English-Hungarian dictionary with English to Gypsy and Hungarian to Gypsy word lists. Budapest: Terebess Publications. [revised edition of Vekerdi 1983].</p>
			<p><b>VRP</b> = Výklad romského pravopisu (Seminář romistiky Katedry středoevropských studií FF UK, Praha). Dostupné na: <Link href="https://romstina.ff.cuni.cz/rp/index.php " color="inherit" >https://romstina.ff.cuni.cz/srn/index_srn.php</Link></p>
		</div >
	)
}

export default Zdroje
