import React from 'react';
import './Definitions.css';
import { blueColor } from '../../styles';
import { Tooltip } from '@mui/material';
import { Sources } from '../constants';
import { stylizeText, stylizeTextPovod } from '../../utils/text';

const DefinitionsROM = ({ signs }: { signs: any }) => {
	const renderZdroj = (zdroje?: string) => {
		if (zdroje && zdroje.length) {
			return (
				<>
					<u style={{ fontSize: '12px', paddingRight: '5px' }}>
						zdroj:
					</u>
					{
						zdroje.split(';').map((zdroj: string, idx: number) => {
							const selectedZdroj = zdroj.trim() as keyof typeof Sources;
							if (selectedZdroj in Sources) {
								return (
									<Tooltip title={Sources[selectedZdroj]} arrow placement='top'>
										<span style={{ fontSize: '12px' }}>{zdroj}</span>
									</Tooltip>
								);
							} else {
								return (<span style={{ fontSize: '12px' }}>{zdroj};</span>)
							}
						})
					}
				</>
			)
		}
	}

	return (
		<>
			{signs?.map((sign: any, idx: number) => {
				const data = sign.data;
				return (
					<div key={idx}>
						<div>
							<span style={{ color: blueColor }}>
								<b>{`${data.sign} ${data.valencia ? `(${data.valencia})` : ''
									}`}</b>
							</span>{' '}
							{data.partOfSpeech && (
								<span style={{ fontSize: '12px' }}>
									<i>({data.partOfSpeech.split(',').join(', ')})</i>
								</span>
							)}{' '}
						</div>
						<div>
							{data.poznamka && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										poznámka:
									</u>
									<span dangerouslySetInnerHTML={{ __html: stylizeText(data.poznamka) }} />
								</div>
							)}
						</div>
						<div>
							{data.povod?.text && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										pôvod:
									</u>
									<span dangerouslySetInnerHTML={{ __html: stylizeTextPovod(data.povod.text) }} />
									{' '}
									{renderZdroj(data.povod.zdroj)}
								</div>
							)}
						</div>
						<div>
							{data.mnozne && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										množné číslo:
									</u>
									{data.mnozne}
								</div>
							)}
						</div>
						<div>
							{data.muzskyMnozne && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										mužský rod, množné číslo:
									</u>
									{data.muzskyMnozne}
								</div>
							)}
						</div>
						<div>
							{data.zenskyJednotne && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										ženský rod, jednotné číslo:
									</u>
									{data.zenskyJednotne}
								</div>
							)}
						</div>
						<div>
							{data.zenskyMnozne && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										ženský rod, množné číslo:
									</u>
									{data.zenskyMnozne}
								</div>
							)}
						</div>
						<div>
							{data.zdrobnenina && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										zdrobnenina:
									</u>
									{data.zdrobnenina}
								</div>
							)}
						</div>
						<div>
							{data.nepriamyKmen && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										nepriamy kmeň:
									</u>
									{data.nepriamyKmen}
								</div>
							)}
						</div>
						<div>
							{data.nominativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										nominatív:
									</u>
									{data.nominativ}
								</div>
							)}
							{data.akuzativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										akuzatív:
									</u>
									{data.akuzativ}
								</div>
							)}
							{data.vokativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										vokatív:
									</u>
									{data.vokativ}
								</div>
							)}
							{data.dativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										datív:
									</u>
									{data.dativ}
								</div>
							)}
							{data.lokativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										lokatív:
									</u>
									{data.lokativ}
								</div>
							)}
							{data.instrumental && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										inštrumentál:
									</u>
									{data.instrumental}
								</div>
							)}
							{data.ablativ && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										ablatív:
									</u>
									{data.ablativ}
								</div>
							)}
							{data.genitiv && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										genitív:
									</u>
									{data.genitiv}
								</div>
							)}
						</div>
						<div>
							{data.pricastie && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										príčastie:
									</u>
									{data.pricastie}
								</div>
							)}
						</div>
						<div>
							{data.mincasnedok && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										minulý čas nedokonavý:
									</u>
									{data.mincasnedok}
								</div>
							)}
							{data.mincasdok && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										minulý čas dokonavý:
									</u>
									{data.mincasdok}
								</div>
							)}

							{data.budcas && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										budúci čas:
									</u>
									{data.budcas}
								</div>
							)}
						</div>
						<div>
							{data.podmiendok && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										podmieňovací spôsob prítomný:
									</u>
									{data.podmiendok}
								</div>
							)}
							{data.podmiennedok && (
								<div style={{ paddingTop: '5px', paddingLeft: '25px' }}>
									<u style={{ fontSize: '12px', paddingRight: '5px' }}>
										podmieňovací spôsob minulý:
									</u>
									{data.podmiennedok}
								</div>
							)}
						</div>
						{data.senses?.map((sense: any, idx: number) => (
							<>
								{sense.translationEquivalents &&
									<ul style={{ marginBottom: '5px' }}>
										{sense.translationEquivalents?.map((te: any, idx: number) => (
											<li key={`te-${idx}`}>
												<div>
													<b>
														{te.TE}
														{' '}
														{te.valencia && <span>{te.valencia}</span>}
													</b>
													{te.poznamka && (
														<div style={{ paddingTop: '5px' }}>
															<u style={{ fontSize: '12px', paddingRight: '5px' }}>
																poznámka:
															</u>
															<span dangerouslySetInnerHTML={{ __html: stylizeText(te.poznamka) }} />
														</div>
													)}
													{sense.partOfSpeech ? (
														<span style={{ fontSize: '12px' }}>
															<i>({sense.partOfSpeech.split(',').join(', ')})</i>
														</span>
													) : null}
												</div>
											</li>
										))}
										{sense.examples &&
											<ul style={{ marginTop: '5px', paddingLeft: '18px' }}>
												{sense.examples?.map((example: any, idx: number) => (
													<li key={idx}>
														<div>
															<div style={{ color: blueColor }}>
																{example.example}
															</div>
															<div>{example.translation}</div>

															{example.source && (
																<div style={{ paddingBottom: '5px' }}>
																	<u style={{ fontSize: '12px', paddingRight: '5px' }}>
																		zdroj:
																	</u>
																	{renderZdroj(example.source)}
																</div>
															)}
															{example.poznamka && (
																<div>
																	<u style={{ fontSize: '12px', paddingRight: '5px' }}>
																		poznámka:
																	</u>
																	{example.poznamka}
																</div>
															)}
														</div>
													</li>

												))}
											</ul>}
									</ul>}
							</>
						))}



						{idx + 1 < signs.length ? <hr /> : null}
					</div>
				);
			})}
		</>

	);
};

export default DefinitionsROM;
