import React, { useContext } from 'react';
import './Definitions.css';
import LanguageContext from '../../context/LanguageContext';
import { ROM, SK } from '../constants';
import DefinitionsSK from './DefinitionsSK';
import DefinitionsROM from './DefinitionsROM';



const Definitions = ({ signs }: { signs: any }) => {
	const { selectedLanguage } = useContext(LanguageContext);

	return (
		<div className="signs">
			{selectedLanguage === SK && (<DefinitionsSK signs={signs} />)}
			{selectedLanguage === ROM && (<DefinitionsROM signs={signs} />)}
		</div>

	);
};

export default Definitions;
