import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from "react-router-dom";

import { PostHogProvider } from 'posthog-js/react'

const phOptions = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


ReactDOM.render(
	<React.StrictMode>
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={phOptions}
		>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PostHogProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
